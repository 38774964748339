import React from "react";
import { Amplify } from "aws-amplify";

import {
  Authenticator,
  ThemeProvider,
  Theme,
  View,
} from "@aws-amplify/ui-react";
import { Logo } from "../Logo";

import { theme } from "../../config/theme";

import "@aws-amplify/ui-react/styles.css";

import awsExports from "../../aws-exports";

Amplify.configure({ ...awsExports });

interface AuthProviderProps {
  children: React.ReactNode;
}

const authenticatorComponents = {
  Header() {
    return (
      <View
        display={`flex`}
        style={{
          justifyContent: `center`,
          marginTop: `2rem`,
          marginBottom: `2rem`,
        }}
      >
        <Logo />
      </View>
    );
  },
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const amplifyTheme: Theme = {
    name: "Crittora Theme",
    tokens: {
      components: {
        authenticator: {
          router: {
            backgroundColor: theme.palette.common.black,
            borderColor: `rgba(255,255,255, 0.5)`,
            boxShadow: `0 0 10px transparent`,
          },
        },
        button: {
          backgroundColor: theme.palette.primary.main,
          borderColor: `rgba(0, 138, 255, 0.5)`,
          color: theme.palette.common.white,
          _loading: {
            backgroundColor: theme.palette.secondary.dark,
          },
          _hover: {
            backgroundColor: `rgba(0, 138, 255, 0.04)`,
            color: theme.palette.primary.light,
            borderColor: `rgb(0, 138, 255)`,
          },

          primary: {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
            _hover: {
              backgroundColor: `rgba(0, 138, 255, 0.04)`,
              color: theme.palette.primary.light,
              borderColor: `rgb(0, 138, 255)`,
            },
          },
          link: {
            color: theme.palette.primary.main,
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
          },
        },
        field: {
          label: {
            color: theme.palette.primary.main,
          },
        },
        textfield: {
          color: theme.palette.primary.main,
        },
        input: {
          color: theme.palette.common.white,
        },
        heading: {
          color: theme.palette.common.white,
        },
      },
    },
  };

  return (
    <ThemeProvider theme={amplifyTheme}>
      <Authenticator hideSignUp components={authenticatorComponents}>
        {children}
      </Authenticator>
    </ThemeProvider>
  );
};
