import React, { useState } from "react";
import { navigate } from "gatsby";
import {
  Box,
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  Collapse,
  useTheme,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { useGetAuthToken } from "../../hooks/useGetAuthToken";

import { Logo } from "../Logo";
import { content } from "./UiShell.content";
import { drawerWidth } from "./UiShell.styles";

interface UiShellDrawerProps {
  mobileOpen: boolean;
  handleDrawerTransitionEnd: () => void;
  handleDrawerClose: () => void;
}

export const UiShellDrawer: React.FC<UiShellDrawerProps> = ({
  mobileOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
}) => {
  const theme = useTheme();
  const { signOut } = useAuthenticator();
  const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});
  const { token, tokenError, tokenIsLoading } = useGetAuthToken();

  const handleClickSignOut = () => {
    signOut();
  };

  const handleToggleCollapse = (index: number) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleNavigate = (route: string) => {
    navigate(route);
    handleDrawerClose();
  };

  const interior = (
    <Box>
      <Toolbar>
        <Logo />
      </Toolbar>

      <List>
        {content.navigation.map((item, index) => (
          <React.Fragment key={index}>
            {item.route ? (
              <ListItemButton onClick={() => handleNavigate(item.route)}>
                <ListItemIcon>
                  <item.icon color="primary" />
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            ) : (
              <>
                <ListItemButton onClick={() => handleToggleCollapse(index)}>
                  <ListItemIcon>
                    <item.icon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                  {openItems[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openItems[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.navigation?.map((subItem, subIndex) => (
                      <Box key={subIndex}>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          onClick={() => handleNavigate(subItem.route)}
                        >
                          <Box pl={6} />
                          <ListItemText primary={subItem.title} />
                        </ListItemButton>
                      </Box>
                    ))}
                  </List>
                </Collapse>
              </>
            )}
          </React.Fragment>
        ))}
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={handleClickSignOut}>
          <ListItemIcon>
            <LogoutIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
          backgroundColor: theme.palette.background.default,
        }}
      >
        {interior}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: theme.palette.background.default,
          },
        }}
        open
      >
        {interior}
      </Drawer>
    </>
  );
};
